import { clsx } from "clsx";
import { SnowplowLink } from "@/components/Tracking/snowplow/SnowplowLink.component";
import type { SimpleArticleListModel } from "../models/simple-article-list-model.types";
type ArticleItemProps = {
  readonly article: SimpleArticleListModel;
  readonly trackingId?: string | null;
  readonly appearance: "ordered" | "unordered";
  readonly index: number;
  readonly orderedNumberClassname?: string;
  readonly position: string;
};
export function SimpleArticleListItem({
  article,
  trackingId,
  appearance,
  index,
  orderedNumberClassname,
  position
}: ArticleItemProps) {
  return <article className="relative flex gap-2" data-k5a-pos={position} key={article.targetUrl} data-sentry-component="SimpleArticleListItem" data-sentry-source-file="SimpleArticleListItem.component.tsx">
      {appearance === "ordered" ? <div className={clsx("shrink-0 basis-3 text-primary", orderedNumberClassname)}>
          {index + 1}
        </div> : null}

      <SnowplowLink className="block grow text-lg hover:underline lg:text-xl" href={article.targetUrl} trackingModel={{
      articleId: article.articleId,
      trackingId: trackingId ?? null,
      url: article.targetUrl
    }} data-sentry-element="SnowplowLink" data-sentry-source-file="SimpleArticleListItem.component.tsx">
        {article.kicker !== null ? <span>{article.kicker} </span> : null}
        {article.headline}
      </SnowplowLink>
    </article>;
}